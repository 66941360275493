import Swiper from 'swiper';
import anime from 'animejs';
import ScrollMagic from 'scrollmagic';
import {TweenMax} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
import {ScrollMagicPluginIndicator} from "scrollmagic-plugins";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);
TweenMax.defaultOverwrite = false;
ScrollMagicPluginIndicator(ScrollMagic);

export function index(controller , scene){

	const nextContainer = document.getElementById('nextContainer');
	const siteHeader = document.getElementById('site-header');
	const reverseHeader = document.getElementById('reverseHeader');
	const fixelem = document.querySelectorAll('.fixelem');
	
	const fv = document.getElementById('fvslide');
	if(fv){
		new Swiper(fv, {
			spaceBetween: 0,
			effect: 'fade',
			speed: 2000,
			loop: true,
			autoplay: {
				delay: 8000,
				disableOnInteraction: false,
			},
		});
	}
	


    if(siteHeader && nextContainer){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: nextContainer,
                offset: Math.floor(window.innerHeight*-.5)+'px'
            })
			//.addIndicators({ name: "header-white" })
            .setClassToggle(siteHeader, 'white').addTo(controller)
        )
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: reverseHeader,
                offset: Math.floor(window.innerHeight*.25) + siteHeader.clientHeight + 'px'
            })
			//.addIndicators({ name: "header-white" })
            .on("enter" , function(e){
				siteHeader.classList.remove('white');
			})
            .on("leave" , function(e){
				siteHeader.classList.add('white');
			})
			.addTo(controller)
        )
    };
	
	
	if(nextContainer && fixelem.length > 0){
		for(let i = 0; i < fixelem.length; i++){
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: nextContainer,
					offset: Math.floor(window.innerHeight*-.75)+'px'
				})
				//.addIndicators({ name: "fix-white-"+i })
				.setClassToggle(fixelem[i], 'white').addTo(controller)
			)
			
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: reverseHeader,
					offset: 0
				})
				//.addIndicators({ name: "fix-white"+i })
				.on("enter" , function(e){
					fixelem[i].classList.remove('white');
				})
				.on("leave" , function(e){
					fixelem[i].classList.add('white');
				})
				.addTo(controller)
			)
		}
	}

};