import Vue from 'vue/dist/vue.min';

export function vm(){
    const v = new Vue({
        el: '#site-app',
		data: function () {
			return {
			  nav: false
			}
		}
    });
};