import {TweenMax} from "gsap";
export function cursol(controller , scene){
    let
        fWidth = 20,
        delay = 10,
        mouseX = 0,
        mouseY = 0,
        posX = 0,
        posY = 0;

	let hov = document.querySelectorAll(".hov");
	
    TweenMax.to({}, .01, {
        repeat: -1,
        onRepeat: function() {
            posX += (mouseX - posX) / delay;
            posY += (mouseY - posY) / delay;
            document.documentElement.style.setProperty('--x', (posX - (window.innerWidth / 2)) / -20 + 'px');
            document.documentElement.style.setProperty('--y', (posY - (window.innerHeight / 2)) / -20 + 'px');
            document.documentElement.style.setProperty('--dx', (posX - (window.innerWidth / 2)) / -40 + 'deg');
            document.documentElement.style.setProperty('--dy', (posY - (window.innerHeight / 2)) / -40 + 'deg');
        }
    });
    document.body.addEventListener("mousemove", function(e){
        mouseX = e.clientX;
        mouseY = e.clientY;
    });
	
    for (let i = 0; i < hov.length; i++) {
        hov[i].removeEventListener("mousemove",mouseHovEnter);
        hov[i].removeEventListener("mouseleave",mouseHovLeave);
        hov[i].addEventListener("mousemove",mouseHovEnter);
        hov[i].addEventListener("mouseleave",mouseHovLeave);
    }
};

function mouseHovEnter(e){
    let pageX = e.pageX;
	let pageY = e.pageY;
	let clientRect = this.getBoundingClientRect();
	let x = e.target.clientWidth/2 - (pageX - (clientRect.left + window.pageXOffset));
	let y = e.target.clientHeight/2 - (pageY - (clientRect.top + window.pageYOffset));
    e.target.style.setProperty('--cx', `${ Math.floor(-1 * x / 2) }px`);
    e.target.style.setProperty('--cy', `${ Math.floor(-1 * y / 2) }px`);
}
function mouseHovLeave(e){
    e.target.style.setProperty('--cx', '0');
    e.target.style.setProperty('--cy', '0');
}