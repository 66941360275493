import ScrollMagic from 'scrollmagic';

import {scrolEvents} from './default';
import {index} from './index';
import {shop} from './shop';
import {cursol} from './cursol';
import {vm} from './vm';

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();

    vm();
    scrolEvents(controller , scene);
    index(controller , scene);
    shop(controller , scene);
    cursol();
});
